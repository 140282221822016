import { createContext, ReactNode, useContext, useEffect } from "react";

import { useLive } from "../graphql/live.query";
import { Live } from "../../../interfaces/live.interface";
//@ts-ignore
import * as Sentry from "@sentry/react";

type Props = {
  children: ReactNode;
  liveKey: string;
  hasAwaitScreen: boolean;
  isGringa: boolean;
  isPolishop: boolean;
  isCountdown: boolean;
};

export type ViewerType = "spectator" | "cockpit";

interface LiveContext {
  key: string;
  live: Live;
  loading: any;
  videoUrl: string;
  hasAwaitScreen: boolean;
  isGringa: boolean;
  isPolishop: boolean;
  isCountdown: boolean;
}

const LiveContext = createContext({} as LiveContext);

export function useLiveContext() {
  return useContext(LiveContext);
}

export default function LiveContectComp({
  children,
  liveKey,
  isGringa,
  isPolishop,
  isCountdown,
  hasAwaitScreen,
}: Props) {
  const { live, loading } = useLive(liveKey);

  useEffect(() => {
    Sentry.setTag("live_key", liveKey);
  }, []);

  return (
    <LiveContext.Provider
      value={{
        key: liveKey,
        live,
        loading,
        videoUrl: live.videoUrl || "",
        hasAwaitScreen,
        isGringa,
        isCountdown,
        isPolishop,
      }}
    >
      {children}
    </LiveContext.Provider>
  );
}
