import { useState } from "react";
import gql from "graphql-tag";
import { FormFieldKind } from "../../../interfaces/form.interface";
import { useMutation, useQuery } from "@apollo/client";

import { useAuth } from "../contexts/AuthContext";
import { ME_TYPE, UPDATE_ME_INFO_TYPE } from "../interfaces/auth.interface";
import { useLiveContext } from "../../Live/contexts/LiveContext";

export const ME = gql`
  query ($liveKey: String!) {
    me {
      key
      uid
      name
      email
      img
      canEditData
      extraInfo(liveKey: $liveKey) {
        isValidated
        externalId
      }
    }
  }
`;

export const UPDATE_ME_INFO = gql`
  mutation ($info: AuthInfoDto!, $liveKey: String!) {
    updatUserInfo(info: $info, liveKey: $liveKey) {
      key
      uid
      name
      email
      img
      canEditData
      extraInfo(liveKey: $liveKey) {
        isValidated
        externalId
      }
    }
  }
`;

interface Props {
  propKey: string;
  value: string;
  type: FormFieldKind;
}

export function useMe(_liveKey?: string) {
  const { key: liveKey } = useLiveContext();
  const { token } = useAuth();
  const { data, loading } = useQuery<ME_TYPE>(ME, {
    skip: !token,
    variables: { liveKey: liveKey || _liveKey },
  });
  const [update, { loading: loadingUpdate, data: updateData }] = useMutation<
    UPDATE_ME_INFO_TYPE,
    {
      liveKey: string;
      info: {
        name: string;
        props: Props[];
        externalId?: string;
      };
    }
  >(UPDATE_ME_INFO);
  const [validateError, setValidateError] = useState<any>({} as any);

  return {
    me: data?.me || {
      email: "",
      img: "",
      name: "",
      uid: "",
      canEditData: true,
      extraInfo: {
        externalId: "",
        isValidated: false,
      },
    },
    loading: loading || loadingUpdate,
    updateData,
    updateError: validateError,
    updateUserInfo: (
      liveKey: string,
      name: string,
      props: Props[],
      externalId?: string
    ) => {
      update({
        variables: {
          liveKey,
          info: {
            name,
            props,
            externalId,
          },
        },
      }).catch(({ graphQLErrors = [] }) => {
        const validationErros = graphQLErrors.filter(
          (error: any) => error.type == "ValidationErros"
        );
        setValidateError(validationErros[0].validationErrors);
      });
    },
  };
}
