import React, { Suspense, useEffect, useState } from "react";
//@ts-ignore
import GoogleFontLoader from "react-google-font-loader";
import {
  ChakraProvider,
  extendTheme,
  Flex,
  Spinner,
  Box,
} from "@chakra-ui/react";
import { BrowserTracing } from "@sentry/tracing";
//@ts-ignore
import * as Sentry from "@sentry/react";
import "./fix.css";

import Graphql from "./Graphql";
import PlayContext from "./context/PlayContext";
import DrawerContext from "./context/DrawerContext";
import TabInFocus from "./context/TabInFocusContext";
import IFrameContext from "./modules/Iframe/contexts/IFrameContext";
import CartContext from "./modules/Cart/contexts/CartContext";
import NotificationContext from "./context/NotificationContext";
import WidgetExpandible from "./modules/Widgets/contexts/WidgetExpandible";
import AnimateContext from "./modules/Widgets/widgets/Chat/contexts/AnimateContext";
import AuthContext, { useAuth } from "./modules/Auth/contexts/AuthContext";
import LiveContext, {
  useLiveContext,
} from "./modules/Live/contexts/LiveContext";
import AnswerContext from "./modules/Widgets/widgets/Chat/contexts/AnswerContext";
import ChatTextContextFocus from "./modules/Widgets/widgets/Chat/contexts/ChatTextContextFocus";

import { useInitialParams } from "./hooks/useInitialParams";
import { useSendLiveEvent } from "./modules/Iframe/hooks/useSendLiveEvent";
import { theme } from "./utils/const/theme";
import { useMe } from "./modules/Auth/graphql/useMe";

const Await = React.lazy(() => import("./modules/Await"));
const Layout = React.lazy(
  () => import("./components/containers/LayoutContainer")
);
const Loading = React.lazy(() => import("./components/stateless_/Loading"));
const Login = React.lazy(
  () => import("./modules/Auth/components/containers/LoginContainer")
);
const LivePinger = React.lazy(
  () => import("./modules/Live/components/containers/LivePinger")
);

const LayoutThemeProvider = ({ children }: { children: JSX.Element }) => {
  const { live, loading } = useLiveContext();

  const sendLiveEvent = useSendLiveEvent();
  useEffect(() => {
    if (live && live.theme && live.theme) {
      try {
        sendLiveEvent(live);
      } catch (error) {}
      // sendButtonColor
    }
  }, [live]);

  if (loading || !live) return null;
  const _theme = JSON.parse(live.theme.themeStyles);

  return (
    <ChakraProvider
      theme={extendTheme({
        ...theme,
        ..._theme,
        colors: {
          ...theme.colors,
          ..._theme.colors,
        },
        // breakpoints,
      })}
    >
      {children}
    </ChakraProvider>
  );
};

const LivePage = () => {
  const { live, hasAwaitScreen, isCountdown } = useLiveContext();
  const { me } = useMe();
  if (live.status == "wait" && hasAwaitScreen)
    return <Await hostname={window.location.hostname} />;
  if (live.status !== "wait" && isCountdown) return null;
  if (live.status === "live")
    return (
      <>
        {me.uid && (
          <Suspense fallback={null}>
            <LivePinger />
          </Suspense>
        )}
        <Layout />\
      </>
    );
  if (live.status == "finish" && live.title.toLowerCase().includes("digitalks"))
    return (
      <Box
        h="100vh"
        style={{
          backgroundImage: "url('/assets/final.png')",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#00161e",
        }}
        w="100vw"
      />
    );
  return null;
};

function App() {
  const initialParams = useInitialParams();

  if (!initialParams.liveKey) return null;
  return (
    <Graphql>
      <>
        <GoogleFontLoader
          fonts={[
            {
              font: "Barlow",
              weights:
                "0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900".split(
                  ","
                ),
            },
            {
              font: "Lato",
              weights:
                "0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900".split(
                  ","
                ),
            },
            {
              font: "Nunito",
              weights:
                "0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900".split(
                  ","
                ),
            },
          ]}
          // subsets={["cyrillic-ext", "greek"]}
        />
        <ChakraProvider
          theme={extendTheme({
            ...theme,
          })}
        >
          <TabInFocus>
            <Suspense fallback={null}>
              <AuthContext {...initialParams}>
                <LiveContext {...initialParams}>
                  <IFrameContext>
                    <NotificationContext>
                      <AnswerContext>
                        <PlayContext>
                          <ChatTextContextFocus>
                            <WidgetExpandible>
                              <LayoutThemeProvider>
                                <DrawerContext>
                                  <AnimateContext>
                                    <CartContext>
                                      <>
                                        <Login />
                                        <LivePage />
                                      </>
                                    </CartContext>
                                  </AnimateContext>
                                </DrawerContext>
                              </LayoutThemeProvider>
                            </WidgetExpandible>
                          </ChatTextContextFocus>
                        </PlayContext>
                      </AnswerContext>
                    </NotificationContext>
                  </IFrameContext>
                </LiveContext>
              </AuthContext>
            </Suspense>
          </TabInFocus>
        </ChakraProvider>
      </>
    </Graphql>
  );
}

function extractLiveKeyFromURL() {
  try {
    const url_splitted = window.location.pathname.split("/");
    return url_splitted[url_splitted.length - 1];
  } catch {
    return "";
  }
}

export default App;
