import gql from "graphql-tag";

export const LIVE_FRAGMENT = gql`
  fragment Live on Live {
    key
    title
    orientation
    videoUrl
    status
    timeCouting
    startDate
    createdAt
    endDate
    banner
    cupom
    bannerMobile
    scheduledDate
    hasCountdown
    companyName
    configurations {
      title
      value
      configName
      dadName
    }
    widget {
      key
      title
      type
      templateKey
      liveKey
      expandible
    }
    chat {
      key
      title
      type
      templateKey
      liveKey
    }
    theme {
      logo
      backgroundImage
      title
      themeStyles
      liveBgColor
    }
    integrationType {
      commerceIntegration
      cartUrl
      stackable
    }
  }
`;
