import { Message } from "../interfaces/message.interface";
import { createContext, useContext, useState } from "react";

type Props = {
  children: JSX.Element;
};

export interface MessageAnswer extends Message {
  messageKey: string;
}

type ContextData = {
  setAnswer: (answer: MessageAnswer | undefined) => void;
  answer: MessageAnswer | undefined;
};

const AnswerContext = createContext({} as ContextData);

export const useAnswer = () => {
  return useContext(AnswerContext) || {};
};

export default ({ children }: Props) => {
  const [answer, setAnswer] = useState<MessageAnswer | undefined>();

  return (
    <AnswerContext.Provider value={{ setAnswer, answer }}>
      {children}
    </AnswerContext.Provider>
  );
};
