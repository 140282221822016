import { useMutation } from "@apollo/client";
import { useDebounce } from "use-debounce/lib";
import { useCallback, useEffect, useRef, useState } from "react";

import { useLiveContext } from "../modules/Live/contexts/LiveContext";

import { useSendGA } from "../modules/Iframe/hooks/useSendGA";
import { REGISTER_USER_TIMELINE } from "../graphql/user-analytics.mutation";

type CommerceEvents =
  | "COMMERCE_CATALOG_OPEN" //DONE
  | "COMMERCE_CATALOG_CLOSE" //DONE
  | "COMMERCE_CATALOG_SCROLL"; //DONE
type CartEvents = "CART_OPEN" | "CART_CHECKOUT" | "CART_PRODUCT_REDIRECT";
type ProductEvents =
  | "PRODUCT_VIEW_IN_HIGHTLIGHT"
  | "PRODUCT_VIEW_IN_CATALOG" //DONE
  | "PRODUCT_VIEW_OUT" //DONE
  | "PRODUCT_BUY_POPUP"
  | "PRODUCT_BUY_CATALOG"; //DONE
type WidgetEvents =
  | "POLL_ANSWER" //DONE
  | "CTA_CLICK" //DONE
  | "CHAT_TYPING" //DONE
  | "CHAT_SEND" //DONE
  | "WIDGET_MINEMIZE" //DONE
  | "WIDGET_MAXMIZE"; //DONE
type UserWindowEvents =
  | "WINDOW_SCROLL"
  | "WINDOW_FIXED"
  | "WINDOW_FLOATING"
  | "WINDOW_IN_DIV"
  | "WINDOW_LANDSCAPE_IN"
  | "WINDOW_LANDSCAPE_OUT"
  | "WINDOW_NAVIGATION"
  | "WINDOW_TAB_VISIBILITY_ACTIVE" //DONE
  | "WINDOW_TAB_VISIBILITY_DISACTIVE"; //DONE

type UserLoginEvents =
  | "LOGIN_POPUP_OPEN" //DONE
  | "LOGIN_POPUP_CLOSE" //DONE
  | "LOGIN_USER_NAME_CHANGE"; //DONE;
type VideoEvents = "VIDEO_SET_VOLUME" | "VIDEO_PAUSE" | "VIDEO_PLAY"; //DONE;;
type UserInteractions = "CUPOM_COPIED"; //DONE
type LoginPopup =
  | "LOGIN_POPUP_MENU_OPEN"
  | "LOGIN_POPUP_MENU_CLOSE"
  | "LOGIN_POPUP_FEEDBACK_OPEN"
  | "LOGIN_POPUP_FEEDBACK_CLOSE"
  | "LOGIN_POPUP_WIDDE_CLICK"
  | "LOGIN_POPUP_LOGIN_INFO_OPEN"
  | "LOGIN_POPUP_LOGIN_INFO_CLOSE"; //DONE;;

export type ConsumerTimelineEventType =
  | ProductEvents
  | UserInteractions
  | CommerceEvents
  | WidgetEvents
  | UserWindowEvents
  | UserLoginEvents
  | VideoEvents
  | CartEvents
  | LoginPopup;
type ScrollArgs = { from: number; to: number };

const Descriptions: any = {
  COMMERCE_CATALOG_SCROLL: (scroll: ScrollArgs) =>
    `Scroll de ${scroll.from}% to ${scroll.to}%`,
  WINDOW_SCROLL: (scroll: ScrollArgs) =>
    `Scroll de ${scroll.from}% to ${scroll.to}%`,
};

export function useOnScrollAnalytics(
  type: "COMMERCE_CATALOG_SCROLL" | "WINDOW_SCROLL"
) {
  const execute = useConsumerAnalytics(type);
  const [scroll, setScroll] = useState(0);
  const [lastScroll, setLastScroll] = useState(0);
  const [_scroll] = useDebounce(scroll, 500);
  const didMountRef = useRef(false);
  const lastValue = useRef([0, 0]);
  useEffect(() => {
    lastValue.current.shift();
    lastValue.current.push(_scroll);
    if (didMountRef.current) {
      execute({
        from: lastValue.current[0],
        to: _scroll,
      });
    }
  }, [_scroll]);
  useEffect(() => {
    didMountRef.current = true;
  }, []);
  return (e: any) => {
    setScroll(Math.floor((e.target.scrollLeft / e.target.scrollWidth) * 100));
  };
}

export function useConsumerAnalytics(
  type: "COMMERCE_CATALOG_SCROLL" | "WINDOW_SCROLL"
): (scroll: ScrollArgs) => {};

export function useConsumerAnalytics(
  type: ProductEvents
): (productKey: string) => {};

export function useConsumerAnalytics(
  type: "CHAT_TYPING" | "CHAT_SEND"
): (text: string) => {};
export function useConsumerAnalytics(
  liveKey: WidgetEvents
): (widgetKey: string) => {};
export function useConsumerAnalytics(
  type: "WINDOW_NAVIGATION"
): (url: string) => {};
export function useConsumerAnalytics(
  type: "VIDEO_SET_VOLUME"
): (setValue: string) => {};
export function useConsumerAnalytics(type: ConsumerTimelineEventType): () => {};

export function useConsumerAnalytics(type: ConsumerTimelineEventType) {
  const { key } = useLiveContext();
  const sendGAEvent = useSendGA();
  const [execute] = useMutation(REGISTER_USER_TIMELINE);
  const func = useCallback(
    (args?: any) => {
      const descriptionBuilder =
        Descriptions[type] || (() => (args || "").toString());

      const description = descriptionBuilder(args);

      enum Actions {
        Chat = "Chat",
        Catalog = "Catalogo",
        Product = "Produto",
        Poll = "Enquete",
        CTA = "CTA",
        Video = "Video",
      }

      const ga_actions: {
        [key: string]: {
          action: Actions;
          label: string;
        };
      } = {
        CHAT_SEND: {
          action: Actions.Chat,
          label: "Envio de mensagem",
        },
        COMMERCE_CATALOG_OPEN: {
          action: Actions.Catalog,
          label: "Abrir",
        },
        COMMERCE_CATALOG_CLOSE: {
          action: Actions.Catalog,
          label: "Fechar",
        },
        PRODUCT_BUY_CATALOG: {
          action: Actions.Catalog,
          label: "Comprar Produto",
        },
        PRODUCT_VIEW_IN_CATALOG: {
          action: Actions.Catalog,
          label: "Ver produto",
        },
        PRODUCT_BUY_POPUP: {
          action: Actions.Product,
          label: "Comprar Produto",
        },
        PRODUCT_VIEW_IN_HIGHTLIGHT: {
          action: Actions.Product,
          label: "Ver Produto",
        },
        CTA_CLICK: {
          action: Actions.CTA,
          label: "Clicou",
        },
        POLL_ANSWER: {
          action: Actions.Poll,
          label: "Respondeu",
        },
        VIDEO_PLAY: {
          action: Actions.Video,
          label: "Play",
        },
        VIDEO_PAUSE: {
          action: Actions.Video,
          label: "Pause",
        },
      };

      const gaEvent = ga_actions[type];
      if (gaEvent) {
        sendGAEvent(gaEvent.action, gaEvent.label);
      }
      execute({
        variables: {
          liveKey: key,
          eventType: type,
          description,
        },
      });
    },
    [type, execute, sendGAEvent]
  );
  return func;
}

export function useConsumerAnalyticsWindow() {
  const { key } = useLiveContext();
  const [execute] = useMutation(REGISTER_USER_TIMELINE);
  return (type: UserWindowEvents, args?: string) => {
    const descriptionBuilder =
      Descriptions[type] || (() => (args || "").toString());

    const description = descriptionBuilder(args);

    execute({
      variables: {
        liveKey: key,
        eventType: type,
        description,
      },
    });
  };
}
