import gql from "graphql-tag";
import { useQuery, useSubscription } from "@apollo/client";

import { useAuth } from "../../Auth/contexts/AuthContext";
import { LIVE_FRAGMENT } from "./live.fragment";
import { LIVE_SUBSCRIPTION, LIVE_SUBSCRIPTION_TYPE } from "./live.subscription";

import { Live } from "../../../interfaces/live.interface";
import { useMe } from "../../Auth/graphql/useMe";

export interface LIVE_TYPE {
  live: Live;
}
export const LIVE = gql`
  query ($key: String!) {
    live(key: $key) {
      ...Live
    }
  }
  ${LIVE_FRAGMENT}
`;

export const LIVE_STATUS = gql`
  query ($key: String!) {
    live(key: $key) {
      key
      status
      videoUrl
    }
  }
`;

export function useLive(liveKey: string) {
  const { me } = useMe(liveKey);
  const { data, loading } = useQuery<LIVE_TYPE>(LIVE, {
    variables: { key: liveKey },
  });
  useQuery(LIVE_STATUS, {
    variables: { key: liveKey },
    pollInterval: 30000,
  });
  useSubscription<LIVE_SUBSCRIPTION_TYPE>(LIVE_SUBSCRIPTION, {
    variables: { key: liveKey },
    skip: !me.uid,
  });
  return {
    live: data ? data.live : ({} as Live),
    loading,
  };
}
