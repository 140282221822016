import { useCallback } from "react";
import { useContextSelector } from "use-context-selector";
import { DrawerContext } from "../../context/DrawerContext";

export function useDrawerActions(id:string) {
  const setEmbedLink: any = useContextSelector(
    DrawerContext,
    ({setEmbedLink}) => setEmbedLink
  );
  const changeState: any = useContextSelector(
    DrawerContext,
    ({changeState}) => changeState
  );
  
  const onClose = useCallback(() => {
    setEmbedLink(null);
    changeState(id, false);
  }, [changeState, setEmbedLink]);

  const onOpen = useCallback(
    (url?: string) => {
      if (url) setEmbedLink({ id, url });
      changeState(id, true);
    },
    [changeState, setEmbedLink]
  );

  return {
    onOpen, onClose
  };
}
