import gql from "graphql-tag";
import { LIVE_FRAGMENT } from "./live.fragment";

export interface LIVE_SUBSCRIPTION_TYPE {
  liveSubscription: Event;
}
export const LIVE_SUBSCRIPTION = gql`
  subscription ($key: String!) {
    liveSubscription(key: $key) {
      ...Live
    }
  }
  ${LIVE_FRAGMENT}
`;
