import { split, HttpLink, InMemoryCache, ApolloProvider } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
import { ApolloClient } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
//@ts-ignore
import * as Sentry from "@sentry/react";
import { getToken } from "./modules/Auth/graphql/useCustomToken";

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API}/graphql`,
  // credentials: "include",
});

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_API_WS_SUBSCRIPTION}/graphql`,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: async () => {
      // const token = await auth.currentUser?.getIdToken();
      return {
        authorization: getToken(),
        "user-agent": navigator.userAgent,
      };
    },
  },
});

const authMiddleware = setContext(async (_, { headers }) => {
  // const token = await auth.currentUser?.getIdToken();
  return {
    headers: {
      ...headers,
      // authorization: token ? `${token}` : "",
      authorization: getToken(),
    },
  };
});

const splitLink = split(
  ({ query }: any) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const errorLink = onError((error) => {
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors) console.log(graphQLErrors);
  // graphQLErrors
  //   .filter((e: any) => e.type == "Business")
  //   .map(({ message }) => alert(message));
  if (networkError) console.log(`[Network error]: ${networkError}`);

  Sentry.captureException(error);
});

export const client = new ApolloClient({
  link: errorLink.concat(authMiddleware.concat(splitLink)),
  cache: new InMemoryCache({
    resultCaching: true,
    dataIdFromObject: (o) => (o.key ? "" + o.key : undefined),
  }),
  credentials: "include",
});

export default function Graphql({ children }: any) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
