import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useConsumerAnalytics } from "../../../hooks/useConsumerAnalytics";
import { useLiveContext } from "../../../modules/Live/contexts/LiveContext";

type Props = {
  children: JSX.Element;
};

// interface WidgetContext = <Record></Record><string, boolean>;
type WidgetExpandibleConfig = {
  itensExpandible: { [key: string]: boolean };
  hasExpandible?: boolean;
};
interface WidgetExpandibleConfigContext extends WidgetExpandibleConfig {
  expandibleChat: (key: string) => void;
  isFake?: boolean;
}

const WidgetExpandibleContext = createContext<WidgetExpandibleConfigContext>(
  {} as WidgetExpandibleConfigContext
);

export function useWidgetExpandible() {
  return useContext(WidgetExpandibleContext);
}

export function useExpandible(key: string) {
  const { itensExpandible, expandibleChat, isFake } = useContext(
    WidgetExpandibleContext
  );

  const expandibleToggle = useCallback(() => {
    expandibleChat(key);
  }, [expandibleChat]);

  if (itensExpandible && !isFake)
    return {
      show: itensExpandible[key],
      expandibleToggle,
    };
  return {
    show: true,
    expandibleToggle: () => {},
  };
}

export function useHasExpandible() {
  const { hasExpandible } = useContext(WidgetExpandibleContext);

  return hasExpandible;
}

export const FakeWidgetExpandible = ({ children }: Props) => {
  return (
    <WidgetExpandibleContext.Provider
      value={{
        itensExpandible: {},
        isFake: true,
        expandibleChat: (key: string) => {},
      }}
    >
      {children}
    </WidgetExpandibleContext.Provider>
  );
};

export default ({ children }: Props) => {
  const [state, setState] = useState<{ [key: string]: boolean }>({
    chat: false,
  });
  const close = useConsumerAnalytics("WIDGET_MINEMIZE");
  const open = useConsumerAnalytics("WIDGET_MAXMIZE");

  const {
    live: { widget },
  } = useLiveContext();

  useEffect(() => {
    // widgetKey
    if (widget) {
      setState((state) => ({
        ...state,
        chat: false,
        [widget.key]: widget.expandible === "MAXIMIZE",
      }));
    }

    return () => {
      if (widget)
        setState((state) => ({
          ...state,
          chat: state[widget.key] ? state.chat : state.chat,
          [widget.key]: widget.expandible === "MINIMIZE",
        }));
    };
  }, [widget]);

  return (
    <WidgetExpandibleContext.Provider
      value={{
        itensExpandible: state,
        hasExpandible: Object.values(state).some((item) => !!item),
        expandibleChat: (key: string) => {
          setState((s) => {
            const show = !s[key];
            if (show) open(key);
            else close(key);
            return {
              [key]: show,
            };
          });
        },
      }}
    >
      {children}
    </WidgetExpandibleContext.Provider>
  );
};
