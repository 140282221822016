export const ZINDEX_BASE = (() => {
  // if (window.location.href.includes("poli")) return 999999;
  return 8;
})();
export const TOP_BASE = (() => {
  // if (window.location.href.includes("poli"))
  //   return window.innerWidth < 980 ? 48 : 150;
  return window.innerWidth < 980 ? 48 : 150;
})();
export const ZINDEX = {
  layer1: ZINDEX_BASE + 1,
  layer2: ZINDEX_BASE + 2,
  layer3: ZINDEX_BASE + 3,
  layer4: ZINDEX_BASE + 4,
  layer5: ZINDEX_BASE + 5,
  layer6: ZINDEX_BASE + 6,
  layer7: ZINDEX_BASE + 7,
  layer8: ZINDEX_BASE + 8,
  layer9: ZINDEX_BASE + 9,
  layer10: ZINDEX_BASE + 10,
  layer11: ZINDEX_BASE + 11,
  layer12: ZINDEX_BASE + 12,
  layerMax: 9999999999999,
};

// export const Positions = {
//   sm: {
//     onFloating: {
//       footer: {
//         bottom: 50,
//       },
//     },
//     floatingPlayer: {
//       paddingBottom: 48,
//     },
//     footerSize: {
//       height: 72,
//     },
//   },
//   landscape: {
//     onFloating: {
//       footer: {
//         bottom: 0,
//       },
//     },
//     floatingPlayer: {
//       paddingBottom: 0,
//     },
//     footerSize: {
//       height: 56,
//     },
//   },
//   lg: {
//     onFloating: {
//       footer: {
//         bottom: 0,
//       },
//     },
//     floatingPlayer: {
//       paddingBottom: 0,
//     },
//     footerSize: {
//       height: 72,
//     },
//   },
// };
