import { useState, useEffect } from "react";

import { useConfirmModal } from "../../hooks/useConfirmModal";
import { useDrawerIsOpen } from "../../hooks/drawer/useDrawerIsOpen";
import { useDrawerActions } from "../../hooks/drawer/useDrawerActions";

import Drawer from "../stateless_/Drawer";

import { DrawerCompProps } from "../../interfaces/components.interface";

export default function DrawerComp(
  props: DrawerCompProps & {
    type: string;
  }
) {
  const { type, ...rest } = props;
  const { isOpen } = useDrawerIsOpen(type);
  const { onClose } = useDrawerActions(type);
  const [alert, setShowAlert] = useState(false);
  const { show, Component } = useConfirmModal({
    title: "Tem certeza que deseja fechar?",
    cb: onClose,
    description: "",
    confirmButton: "Sim, fechar",
  });
  useEffect(() => {
    let interval = setTimeout(() => {
      setShowAlert(true);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <Component />
      <Drawer
        {...rest}
        isOpen={isOpen}
        alert={alert}
        show={show}
        onClose={onClose}
      />
    </>
  );
}
