import { ReactNode, useState, useCallback, useEffect } from "react";
import { createContext, useContext } from "use-context-selector";

import { Live } from "../../../interfaces/live.interface";
import { Product } from "../../../modules/Cart/contexts/CartContext";
import { CommerceProduct } from "../../../modules/Commerce/interfaces/commerce.interface";

type Props = {
  children: ReactNode;
};
interface IframeState {
  isIframe: boolean;
  justLive: boolean;
}

export type EventSendType =
  | "send_init_cart"
  | "send_update_cart_product"
  | "send_remove_cart_all_product"
  | "send_add_cart_product"
  | "send_remove_cart_product"
  | "send_redirect"
  | "send_current_product"
  | "send_product_data"
  | "send_product_data_loading"
  | "send_fullscreen"
  | "send_notification_click"
  | "send_click_cupom"
  | "send_event"
  | "send_video_status"
  | "send_token"
  | "send_ga_event"
  | "send_countdown";
export type EventReciveType =
  | "recive_notification"
  | "buy_product"
  | "recive_open_product"
  | "recive_cart_update_state"
  | "recive_notification_status"
  | "recive_window_events"
  | "recive_player_status"
  | "recive_product_events"
  | "maxmize"
  | "minemize";

export type CartEvent =
  | "CART_LOADING"
  | "CART_SET"
  | "CART_REMOVE_PRODUCT"
  | "CART_ADD_PRODUCT";
export interface IFrameSendEvent {
  type: EventSendType;
  data: any;
}
export interface IFrameReciveEvent {
  type: EventReciveType;
  data: any;
}

export interface DataAddCart {
  product: Product;
  quantity?: number;
}
export interface DataRemoveCart {
  key: string;
  quantity?: number;
}
export interface DataUpdateCart {
  key: string;
  quantity: number;
}

export interface IframeContextType extends IframeState {
  sendEvent: (eventType: EventSendType, data?: any) => void;
  sendCountdown: (data: boolean) => void;
  sendAddCartProduct: (data: DataAddCart) => void;
  sendUpdateCartProduct: (data: DataUpdateCart) => void;
  sendRemoveCartAllProduct: (data: DataRemoveCart) => void;
  internalRedirect: (url: string) => void;
  sendCartEvent: (cartEvent: CartEvent) => void;
  viewProduct: (product: CommerceProduct) => void;
  viewProductLoading: (loading: boolean) => void;
  sendLiveEvent: (event: Live) => void;
  sendVideoStatus: (status: boolean) => void;
  sendSetFullScreen: (state?: boolean) => void;
  sendCurrentProduct: (product: CommerceProduct[]) => void;
  sendNotificationClick: (enable: boolean) => void;
  sendGAEvent: (action: string, label: string) => void;
  event: IFrameReciveEvent | null;
  isFullScreen: boolean;
}

export const IframeContext = createContext({} as IframeContextType);

export function useIframeContext() {
  return useContext(IframeContext);
}

export default function IFrameContextComp({ children }: Props) {
  const [{ isIframe, justLive }, setState] = useState<IframeState>({
    isIframe: window.location !== window.parent.location,
    justLive: window.location !== window.parent.location,
  });
  const [isFullScreen, setFullScreen] = useState(
    window.location === window.parent.location
  );
  const [event, setEvent] = useState<IFrameReciveEvent | null>(null);
  useEffect(() => {
    window.onmessage = function (e) {
      try {
        const data = JSON.parse(e.data);
        if (data.type) setEvent(JSON.parse(e.data));
      } catch (e) {}
    };
  }, []);
  const sendEvent = useCallback((eventType: EventSendType, data?: any) => {
    //@ts-ignore
    window.top.postMessage(
      JSON.stringify({
        type: eventType,
        data,
      }),
      "*"
    );
  }, []);

  const sendUpdateCartProduct = useCallback(
    (data: { key: string; quantity: number }) => {
      sendEvent("send_update_cart_product", data);
    },
    []
  );
  const sendRemoveCartAllProduct = useCallback(
    (data: { key: string; quantity?: number }) => {
      sendEvent("send_remove_cart_all_product", data);
    },
    []
  );
  const sendAddCartProduct = useCallback(
    (data: { product: Product; quantity?: number }) => {
      sendEvent("send_add_cart_product", data);
    },
    []
  );
  const sendRemoveCartProduct = useCallback((key: string) => {
    sendEvent("send_remove_cart_product", key);
  }, []);
  const internalRedirect = useCallback((url: string) => {
    sendEvent("send_redirect", url);
  }, []);
  const sendCurrentProduct = useCallback((product: CommerceProduct[]) => {
    sendEvent("send_current_product", product);
  }, []);

  const viewProduct = useCallback((product: CommerceProduct) => {
    sendEvent("send_product_data", product);
  }, []);
  const viewProductLoading = useCallback((loading: boolean) => {
    sendEvent("send_product_data", loading);
  }, []);
  const sendLiveEvent = useCallback((event: Live) => {
    sendEvent("send_event", event);
  }, []);
  const sendVideoStatus = useCallback((status: boolean) => {
    sendEvent("send_video_status", status);
  }, []);
  const sendSetFullScreen = useCallback((state: boolean = true) => {
    sendEvent("send_fullscreen", state);
  }, []);
  const sendNotificationClick = useCallback((enable: boolean) => {
    sendEvent("send_notification_click", enable);
  }, []);
  const sendGAEvent = useCallback((action: string, label: string) => {
    sendEvent("send_ga_event", { action, label });
  }, []);

  // FLAG.TODO ☑️🏳️: Qual o evento aqui? Recebe ou envia?
  const sendCartEvent = useCallback((cartEvent: string) => {
    sendEvent("send_ga_event", { cartEvent });
  }, []);
  const sendCountdown = useCallback((data: boolean) => {
    sendEvent("send_countdown", data);
  }, []);

  useEffect(() => {
    if (event) {
      const { type } = event;
      if (type == "maxmize" || type == "minemize") {
        setState((state) => ({
          ...state,
          justLive: type == "minemize",
        }));
      }
    }
  }, [event]);
  useEffect(() => {
    if (event) {
      const { type, data } = event;
      if (type == "recive_window_events") {
        if (data.type == "WINDOW_FIXED") {
          setFullScreen(true);
        }
        if (["WINDOW_FLOATING", "WINDOW_IN_DIV"].includes(data.type)) {
          setFullScreen(false);
        }
        // setFullScreen(type == "maxmize");
      }
    }
  }, [event]);

  return (
    <IframeContext.Provider
      value={{
        justLive, //
        isIframe, //
        event, //
        isFullScreen,
        internalRedirect, //
        viewProduct, //
        viewProductLoading, //
        sendCurrentProduct, //
        sendLiveEvent, //
        sendSetFullScreen, //
        sendNotificationClick, //
        sendVideoStatus, //
        sendGAEvent, //
        sendCartEvent, //
        sendUpdateCartProduct, //
        sendRemoveCartAllProduct, //
        sendAddCartProduct, //
        sendEvent, //
        sendCountdown,
      }}
    >
      {children}
    </IframeContext.Provider>
  );
}
