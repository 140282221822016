import { ZINDEX, ZINDEX_BASE } from "./z-index";

export const theme: any = {
  fonts: {
    // heading: "Barlow",
    body: null,
  },
  components: {
    Input: {
      variants: {
        widde_default: {
          field: {
            border: "1px solid",
            borderColor: "secondary_gray_light_30",
            backgroundColor: "transparent",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            outline: "none",
            ":focus": {
              borderColor: "secondary_gray_dark_60",
              boxShadow: "none !important",
              outline: "none",
            },
            ":hover": {},
          },
        },
      },
      defaultProps: {
        variant: "widde_default",
      },
    },
    Textarea: {
      variants: {
        widde_default: {
          field: {
            border: "1px solid",
            borderColor: "secondary_gray_light_30",
            backgroundColor: "transparent",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            outline: "none",
            ":focus": {
              borderColor: "secondary_gray_dark_60",
              boxShadow: "none !important",
              outline: "none",
            },
            ":hover": {},
          },
        },
      },
      defaultProps: {
        variant: "widde_default",
      },
    },

    Select: {
      variants: {
        widde_default: {
          field: {
            border: "1px solid",
            borderColor: "secondary_gray_light_30",
            backgroundColor: "transparent",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            outline: "none",
            ":focus": {
              borderColor: "secondary_gray_dark_60",
              boxShadow: "none !important",
              outline: "none",
            },
            ":hover": {},
          },
        },
      },
      defaultProps: {
        variant: "widde_default",
      },
    },
    Button: {
      variants: {
        primary: () => ({
          background: "primary_black_100",
          outline: "none !important",
          color: "light",
          ":hover": {
            background: "primary_black_80",
          },
          ":active": {
            background: "primary_black_100",
          },
          ":disabled": {
            background: "primary_black_50",
            color: "secondary_black_50",
          },
        }),
        secondary: () => ({
          background: "light",
          border: "1px solid",
          borderColor: "primary_black_50",
          color: "primary_black_100",
          outline: "none !important",
          ":hover": {
            background: "secondary_gray_30",
          },
          ":active": {
            background: "primary_black_80",
          },
          ":disabled": {
            color: "primary_black_50",
            background: "primary_black_50",
            borderColor: "primary_black_40",
          },
        }),
        tertiary: () => ({
          background: "tertiary.80",
          color: "light",
          outline: "none !important",
          ":hover": {
            background: "tertiary.60",
          },
          ":active": {
            background: "tertiary.100",
          },
          ":disabled": {
            color: "tertiary.30",
            background: "tertiary.50",
          },
        }),
      },
    },
  },
  colors: {
    placeholder: "#BEBFC2",
    //primary
    primary_black_100: "#050508",
    primary_black_90: "#262A33",
    primary_black_80: "#373739",
    primary_black_50: "#828283",
    primary_black_40: "#9b9b9c",
    primary_black_30: "#B4B4B5",
    //secondaty
    secondary_gray_100: "#D4D4D4",
    secondary_gray_60: "#DCDCDC",
    secondary_gray_40: "#E9E9E9",
    secondary_gray_30: "#F2F2F2",
    secondary_gray_light_30: "#CBD5E0",
    secondary_gray_light_20: "#E2E8F0",
    secondary_gray_dark_60: "#4A5568",
    // others
    complementary_red: "#EC4D3D",
    complementary_orange: "#EC6434",
    complementary_pink: "#CF3AA2",
    complementary_purple: "#5F36E0",
    complementary_green: "#93F972",
    complementary_yellow: "#E5F754",

    tertiary: {
      "100": "#164CC4",
      "80": "#235DDE",
      "60": "#3471F7",
      "50": "#91aeee",
      "30": "#e9effc",
    },
    //
    light: "#FFFFFF",
    error_red: "#D43A2A",
    success_green: "#63CC41",

    // bg-new-buttons
    bgNewButtonsColor: (() => {
      if (window.location.href.toLowerCase().includes("digitalks"))
        return "#004d70";
      if (window.location.href.toLowerCase().includes("polishop"))
        return "#a61014";
      return "#05050866";
    })(),
    bgNewButtons: "#05050866",
    bgTextArea: "#060609b3",
  },

  radii: {
    none: "0",
    sm: "0.125rem",
    base: "0.25rem",
    md: "2px",
    lg: "0.5rem",
    xl: "0.75rem",
    "2xl": "1rem",
    "3xl": "1.5rem",
    full: "9999px",
  },
  zIndices: {
    auto: "auto",
    banner: ZINDEX_BASE + 1200,
    base: ZINDEX_BASE + 0,
    docked: ZINDEX_BASE + 10,
    dropdown: ZINDEX_BASE + 1000,
    hide: -1,
    modal: ZINDEX.layerMax + 1400,
    overlay: ZINDEX_BASE + 1300,
    popover: ZINDEX.layerMax + 1500,
    skipLink: ZINDEX_BASE + 1600,
    sticky: ZINDEX_BASE + 1100,
    toast: ZINDEX.layerMax + 1700,
    tooltip: ZINDEX.layerMax + 1800,
  },
  styles: {
    global: {
      body: {
        fontFamily: null,
        lineHeight: null,
        color: null,
        background: "transparent !important",
      },
      "*": {
        fontFamily: "Lato !important",
        position: "relative",
      },
      "h1, h2, h3, h4, h5, h6": {
        fontFamily: "Barlow !important",
      },
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
        borderRadius: "8px",
        backgroundColor: `rgba(0, 0, 0, 0.0)`,
      },
      "*::-webkit-scrollbar-thumb": {
        borderRadius: "8px",
        backgroundColor: `gray.500`,
      },
      ':focus:not(:focus-visible):not([role="dialog"]):not([role="menu"])': {
        boxShadow: "none !important",
      },
    },
  },
};
