import {
  Drawer as DrawerChakra,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerFooter,
  DrawerBody,
} from "@chakra-ui/react";

import { CompProps } from "../../interfaces/components.interface";
import { ZINDEX } from "../../utils/const/z-index";

export default function Drawer({
  title,
  children,
  position,
  footer,
  bgCloseBtn = "unset",
  bg = "background",
  borderTopRadius,
  closeBtn = true,
  overlayBg,
  confirmModal,
  height,
  maxH,
  minH,
  colorContent = "white",
  zIndexIncrement = 0,
  customCloseBtn = <></>,
  flexDirection,
  onCloseEmit,
  isOpen,
  alert,
  show,
  onClose,
}: CompProps) {
  return (
    <DrawerChakra
      isOpen={isOpen}
      placement={position ? position : "left"}
      onClose={() => {
        if (!!onCloseEmit) onCloseEmit();
        if (confirmModal && alert) {
          show();
        } else {
          onClose();
        }
      }}
      size="sm"
    >
      <DrawerOverlay
        zIndex={ZINDEX.layerMax + zIndexIncrement}
        bgColor={overlayBg}
      >
        <DrawerContent
          height={height ?? "unset"}
          maxH={maxH}
          minH={minH}
          bg={bg}
          color={colorContent}
          borderTopRadius={borderTopRadius}
          overflow="auto"
        >
          {closeBtn && !customCloseBtn && (
            <DrawerCloseButton
              w={[6, 8]}
              h={[6, 8]}
              mt={[position !== "right" ? "2" : "10", "2"]}
              bg={bgCloseBtn}
              zIndex={ZINDEX.layerMax}
            />
          )}
          {customCloseBtn && customCloseBtn}
          {title && <DrawerHeader color="fontColor">{title}</DrawerHeader>}
          <DrawerBody
            flexDirection={flexDirection}
            display={flexDirection ? "flex" : undefined}
            h="100%"
            py={title ? "2" : 0}
            px={title ? "3" : 0}
          >
            {children}
          </DrawerBody>
          {footer && <DrawerFooter w="100%">{footer}</DrawerFooter>}
        </DrawerContent>
      </DrawerOverlay>
    </DrawerChakra>
  );
}
