import { useState, ReactNode, useCallback, useRef } from "react";
import { createContext, useContext } from "use-context-selector";

import { Box, useBreakpointValue } from "@chakra-ui/react";
import Drawer from "../components/containers/DrawerContainer";
import Loading from "../components/stateless_/Loading";

interface DrawerProviderProps {
  children: ReactNode;
}

type StateType = {
  [key: string]: boolean;
};
type EmbedType = {
  id: string;
  url: string;
};

type Styles = {
  position?: "right" | "left" | "top" | "bottom";
  h?: string | "unset";
  w?: string | "unset";
};

type DrawerAdminContextData = {
  changeState: (id: string, state: boolean) => void;
  setEmbedLink: (embed: EmbedType | null) => void;
  state: StateType;
  styles?: Styles;
  setStyles: (styles: Styles) => void;
};

export const DrawerContext = createContext({} as DrawerAdminContextData);

export default function DrawerContextComp({ children }: DrawerProviderProps) {
  const [state, setState] = useState<StateType>({});
  const [embedLink, setEmbedLink] = useState<EmbedType | null>(null);
  const [styles, setStyles] = useState<Styles>({
    position: "right",
    h: "unset",
    w: "unset",
  });

  const changeState = useCallback(
    (id: string, state: boolean) => {
      setState((stateCurrent) => ({
        ...stateCurrent,
        [id]: state,
      }));
    },
    [setState]
  );

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });
  const size = useBreakpointValue({
    base: "sm",
    md: "md",
    lg: "lg",
  });
  return (
    <DrawerContext.Provider
      value={{ state, changeState, setEmbedLink, styles, setStyles }}
    >
      {embedLink && state[embedLink.id] === true && (
        <Drawer
          type={embedLink.id}
          position={size === "sm" ? styles.position : "right"}
          closeBtn={!isWideVersion}
          height={[styles.h ?? "100%", "unset"]}
          bgCloseBtn="rgb(0,0,0, 0.5)"
          confirmModal
        >
          <Box w="100%" h="100%" position="relative">
            <iframe
              src={`${embedLink.url}`}
              width="100%"
              allowFullScreen
              height="100%"
              style={{ zIndex: 2, position: "absolute" }}
            ></iframe>
            <Box position="absolute" w="100%" h="100%" zIndex={1}>
              <Loading />
            </Box>
          </Box>
        </Drawer>
      )}

      {children}
    </DrawerContext.Provider>
  );
}

export const useDrawer = (id: string) => {
  const { state, changeState, setEmbedLink, styles, setStyles } =
    useContext(DrawerContext);

  const onClose = useCallback(() => {
    setEmbedLink(null);
    changeState(id, false);
  }, [changeState, setEmbedLink]);

  const onOpen = useCallback(
    (url?: string) => {
      if (url) setEmbedLink({ id, url });
      changeState(id, true);
    },
    [changeState, setEmbedLink]
  );
  return {
    isOpen: state[id] === true,
    onClose,
    onOpen,
    styles,
    setStyles: (styles: Styles) => setStyles(styles),
  };
};
