import { createContext, ReactNode, useContext } from "react";
import { useToast, useBreakpointValue } from "@chakra-ui/react";

type Props = {
  children: ReactNode;
};

type Notification = "error" | "warning" | "success";

interface NotificationContext {
  handleToast: (notification: NotificationType) => void;
}

interface NotificationType {
  value: string;
  type: Notification;
}

const NotificationContext = createContext({} as NotificationContext);

export function useNotificationContext() {
  return useContext(NotificationContext);
}

export default function NotificationContextComp({ children }: Props) {
  const toast = useToast();
  const size = useBreakpointValue({
    base: "sm",
    lg: "lg",
  });

  const handleToast = (notification: NotificationType) => {
    if (!!notification && !!notification.value) {
      toast({
        title: notification.value,
        status: notification.type || "info",
        isClosable: true,
        position: size === "sm" ? "top" : "top-left",
        containerStyle: {
          top: size === "sm" ? "-6px" : undefined,
          width: size === "sm" ? "80%" : undefined,
          fontSize: size === "sm" ? "12px" : undefined,
        },
      });
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        handleToast: (notification: NotificationType) =>
          handleToast(notification),
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
