import {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useMe } from "../modules/Auth/graphql/useMe";
import { useConsumerAnalytics } from "../hooks/useConsumerAnalytics";
import { useLiveContext } from "../modules/Live/contexts/LiveContext";
import { useEventIframe } from "../modules/Iframe/hooks/useEventIframe";

type Props = {
  children: ReactNode;
};

interface PlayContext {
  setVideoPlayer: (player: HTMLVideoElement | null) => void;
  setPlaying: (state: boolean) => void;
  playing: boolean;
  setVolume: (volume: number) => void;
  volume: number;
  toggleMutte: () => void;
  showVolume: boolean;
}

const PlayContext = createContext({} as PlayContext);

export function usePlay() {
  return useContext(PlayContext);
}

export default function PlayContextComp({ children }: Props) {
  const { live } = useLiveContext();
  const { me } = useMe();
  const [playing, setPlaying] = useState<boolean>(false);
  const [showVolume, setShowVolume] = useState<boolean>(true);

  const analyticsSetValue = useConsumerAnalytics("VIDEO_SET_VOLUME");
  const analyticsPlay = useConsumerAnalytics("VIDEO_PLAY");
  const analyticsPause = useConsumerAnalytics("VIDEO_PAUSE");
  //TO.Do
  const didMountPlayRef = useRef(false);
  const didMountSoundRef = useRef(false);
  const videoRef = useRef<any>();
  const defaultVolume = 0;
  const [value, setValue] = useState(defaultVolume);
  const lastValue = useRef(defaultVolume);
  const lastStatus = useRef();
  const { event } = useEventIframe(["recive_player_status"]);

  const mute = useCallback(() => {
    setValue((value) => {
      if (value > 0) lastValue.current = value;
      return 0;
    });
  }, []);
  useEffect(() => {
    if (!videoRef.current) return;
    // plavolume
    if (value > 0) {
      videoRef.current.muted(false);
      videoRef.current.volume(value / 100);
    } else {
      videoRef.current.muted(true);
    }
  }, [value]);

  useEffect(() => {
    if (event?.type == "recive_player_status") {
      const playerStatus = event.data;
      if (playerStatus === "Hide") {
        mute();
      } else {
        if (lastStatus.current === "Hide") {
          setValue(lastValue.current);
        }
      }
      lastStatus.current = playerStatus;

      // if (playerStatus !== "Hide") toggleMutte();
    }
  }, [event, mute]);

  const toggleMutte = useCallback(() => {
    const _value = value > 0 ? 0 : lastValue.current || 100;
    lastValue.current = value;
    if (videoRef.current) {
      videoRef.current.muted(_value == 0);
      videoRef.current.volume(_value);
    }
    setValue(_value);
  }, [value]);

  useEffect(() => {}, []);
  useEffect(() => {
    if (didMountSoundRef.current && me.extraInfo.isValidated)
      analyticsSetValue((value || "").toString());
    didMountSoundRef.current = true;
  }, [value]);
  useEffect(() => {
    if (didMountPlayRef.current) {
      if (playing) analyticsPlay();
      else analyticsPause();
    }

    if (playing) {
      setShowVolume(true);
    }
    didMountPlayRef.current = true;
  }, [playing]);

  const setVideoPlayer = useCallback((player) => {
    videoRef.current = player;
  }, []);

  return (
    <PlayContext.Provider
      value={{
        showVolume,
        playing: playing,
        setPlaying: setPlaying,
        volume: value,
        setVolume: setValue,
        toggleMutte,
        setVideoPlayer,
      }}
    >
      {children}
    </PlayContext.Provider>
  );
}
