import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogContent,
  Button,
} from "@chakra-ui/react";

type Props = {
  cb: () => void;
  title: string;
  description: string;
  confirmButton: string;
};

export function useConfirmModal({
  cb,
  title,
  description,
  confirmButton,
}: Props) {
  const [isOpen, setShow] = useState(false);
  const cancelRef: any = React.useRef();
  return {
    show: () => setShow(true),
    Component: () => (
      <AlertDialog
        motionPreset="slideInBottom"
        onClose={() => setShow(false)}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          <AlertDialogCloseButton />
          {description && <AlertDialogBody>{description}</AlertDialogBody>}
          <AlertDialogFooter>
            <Button onClick={cb}>{confirmButton}</Button>
            <Button
              colorScheme="red"
              ml={3}
              ref={cancelRef}
              onClick={() => setShow(false)}
            >
              Cancelar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    ),
  };
}
