import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from "react";

type Props = {
  children: ReactNode;
};

interface TabInFocusContext {
  tabVisible: boolean;
}

const TabInFocusContext = createContext({} as TabInFocusContext);

export function useTabInFocus() {
  return useContext(TabInFocusContext);
}

export default function TabInFocus({ children }: Props) {
  const [tabVisible, setTabVisible] = useState(true);

  useEffect(() => {
    const handleVisibility = () => {
      setTabVisible(document.visibilityState === "visible");
    };

    window.addEventListener("visibilitychange", handleVisibility);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibility);
    };
  }, []);

  return (
    <TabInFocusContext.Provider
      value={{
        tabVisible: tabVisible,
      }}
    >
      {children}
    </TabInFocusContext.Provider>
  );
}
