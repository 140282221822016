import gql from "graphql-tag";
import { useCallback, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import { EventSendType } from "../../../modules/Iframe/contexts/IFrameContext";
import {
  getQueryParam,
  useInitialParams,
} from "../../../hooks/useInitialParams";
import { CUSTOM_TOKEN_TYPE } from "../interfaces/auth.interface";
import { client } from "../../../Graphql";
import { storage } from "../../../utils/helpers/local-storage";
import { URL_INITIAL_PARAMS } from "../../../utils/const/urlInitialParams";

type TokenStorage = string;
function makeid(length: number) {
  try {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  } catch (error) {
    return new Date().getTime().toString();
  }
}

let token: string;
export function getToken(): string {
  let anonymous_token = "@widde-new-live-token";

  if (token) return token;
  // initi token
  let initToken = getQueryParam(URL_INITIAL_PARAMS.token);
  const { get, setItem } = storage(anonymous_token);
  const fronStorage = get();
  if (!!initToken) {
    setItem(initToken);
    token = initToken;
    return initToken;
  }
  if (!!fronStorage) token = fronStorage;
  if (!!fronStorage) return fronStorage;
  // if()
  const customToken = `widde_${makeid(16)}${Math.floor(
    Math.random() * 100000000
  )}${new Date().getTime().toString()}`;
  // return client.query({ query: CUSTOM_TOKEN }).then(({ data }) => {
  setItem(customToken);
  token = customToken;
  return customToken;
  // });
}

export function useCustomToken() {
  const [token, setToken] = useState("");

  const sendEvent = useCallback((eventType: EventSendType, data?: any) => {
    //@ts-ignore
    window.top.postMessage(
      JSON.stringify({
        type: eventType,
        data,
      }),
      "*"
    );
  }, []);

  useEffect(() => {
    const _token = getToken();
    try {
      sendEvent("send_token", _token);
    } catch (error) {}
    setToken(_token);
  }, []);

  return { token };
}
