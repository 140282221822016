import { useContextSelector } from "use-context-selector";
import { DrawerContext } from "../../context/DrawerContext";

export function useDrawerIsOpen(id: string) {
  const state: any = useContextSelector(DrawerContext, ({ state }) => state);

  return {
    isOpen: state[id] === true,
  };
}
