import { useBreakpointValue } from "@chakra-ui/react";
import { createContext, useState, useEffect, useContext } from "react";
import { useSendSetFullScreen } from "../../../../Iframe/hooks/useSendLogin";

type Props = {
  children: JSX.Element;
};

// interface WidgetContext = <Record></Record><string, boolean>;
type ChatTextFocus = {
  isFocus: boolean;
};
interface ChatTextFocusContextType extends ChatTextFocus {
  setFocus: (focus: boolean) => void;
}

const ChatTextFocusContext = createContext<ChatTextFocusContextType>(
  {} as ChatTextFocusContextType
);

export function useChatTextFocusContext() {
  return useContext(ChatTextFocusContext);
}

export default ({ children }: Props) => {
  const [state, setState] = useState(false);
  const sendSetFullScreen = useSendSetFullScreen();
  const size = useBreakpointValue({
    base: "sm",
    md: "md",
    lg: "lg",
  });

  useEffect(() => {
    if (state && size !== "lg") {
      sendSetFullScreen();
    }
  }, [state]);
  return (
    <ChatTextFocusContext.Provider
      value={{
        isFocus: state,
        setFocus: setState,
      }}
    >
      {children}
    </ChatTextFocusContext.Provider>
  );
};
