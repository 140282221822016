import { createContext, useContext, useState, useEffect } from "react";

type Props = {
  children: JSX.Element;
};

type ContextData = {
  initAnimation: (animate: string) => void;
  animate: string | null;
};

const AnimateContext = createContext({} as ContextData);

export const useAnimate = () => {
  return useContext(AnimateContext) || {};
};

export default ({ children }: Props) => {
  const [animate, setAnimate] = useState<string | null>(null);

  useEffect(() => {
    if (!animate) return;
    let interval = setTimeout(() => {
      setAnimate(null);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [animate]);

  return (
    <AnimateContext.Provider
      value={{
        animate,
        initAnimation: (animation: string) => setAnimate(animation),
      }}
    >
      {children}
    </AnimateContext.Provider>
  );
};
