import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from "react";
import { useEventIframe } from "../../Iframe/hooks/useEventIframe";

export interface Product {
  key: string;
  title: string;
  price: number;
  mainImage: string;
  externalId: string;
  quantity: number;
  url?: string;
}

export interface CartObject {
  amount: number;
  products: Product[];
}

export interface CartState extends CartObject {
  loading: boolean;
}

type Props = {
  children: ReactNode;
};

interface CartContext {
  isOpen: boolean;
  lastUpdate?: number;
  toggleOpen: () => void;
  cart: CartState;
}

const CartContext = createContext({} as CartContext);

export function useCartContext() {
  return useContext(CartContext);
}

export default function CartContextComp({ children }: Props) {
  const { event } = useEventIframe(["recive_cart_update_state"]);
  const [isOpen, setIsOpen] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(new Date().getTime());
  const [cartState, setCartState] = useState<CartState>({
    amount: 0,
    loading: false,
    products: [],
  });

  const toggleOpen = () => {
    setIsOpen((current) => !current);
  };

  useEffect(() => {
    if (!event) return;
    if (event.type === "recive_cart_update_state") {
      setCartState((current) => ({ ...current, ...event.data }));
      const _state: CartState = event.data;
      if (!_state.loading) setLastUpdate(new Date().getTime());
    }
  }, [event]);
  return (
    <CartContext.Provider
      value={{
        isOpen,
        toggleOpen,
        lastUpdate,
        cart: cartState,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
