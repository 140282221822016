import gql from "graphql-tag";

export const REGISTER_USER_TIMELINE = gql`
  mutation ($eventType: String!, $liveKey: String!, $description: String) {
    registerUserTimeline(
      eventType: $eventType
      liveKey: $liveKey
      description: $description
    )
  }
`;
