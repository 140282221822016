import {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from "react";
import { useInitialParams } from "../../../hooks/useInitialParams";
import { useCustomToken } from "../../Auth/graphql/useCustomToken";
//@ts-ignore
import { useMe } from "../graphql/useMe";
//@ts-ignore
import * as Sentry from "@sentry/react";
import { ME_TYPE } from "../interfaces/auth.interface";

type Props = {
  children: ReactNode;
  name?: string;
  email?: string;
};

export enum Providers {
  Google,
  Microsoft,
  Facebook,
  Anonymous,
}

interface AuthContextData {
  loading: boolean;
  token: string;
  canEditData: boolean;
}

const AuthContext = createContext({} as AuthContextData);

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthContextComp({ children, name, email }: Props) {
  const { liveKey } = useInitialParams();
  const { token } = useCustomToken();
  const [loading, setLoading] = useState(true);

  const { updateUserInfo, me } = useMe();
  useEffect(() => {
    if (me.uid) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    // auth.onAuthStateChanged((user) => {
    //   setUser(user);
    //   if (user) setLoading(false);
    //   else handleSignInAnonymous();
    //   console.timeEnd("auth-context");
    // });
  }, [me]);

  useEffect(() => {
    if (!!me && !!me.uid) {
      if (name && email) {
        updateUserInfo(liveKey, name, [], email);
      }
    }
  }, [me]);

  useEffect(() => {
    if (!!me) {
      Sentry.setUser({ ...me });
    }
  }, [me]);

  return (
    <AuthContext.Provider
      value={{
        token,
        loading,
        canEditData: !!name,
      }}
    >
      {/* TO.DO -> Carregar mesmo sem estar logado */}
      {children}
    </AuthContext.Provider>
  );
}
