import { useContextSelector } from "use-context-selector";
import {
  EventReciveType,
  IframeContext,
  IframeContextType,
  IFrameReciveEvent,
} from "../contexts/IFrameContext";

export function useEventIframe(type: EventReciveType[]) {
  const event = useContextSelector<IframeContextType, IFrameReciveEvent | null>(
    IframeContext,
    (iframe) =>
      !type || (iframe.event && type.includes(iframe.event.type))
        ? iframe.event
        : null
  );
  const sendEvent: any = useContextSelector(
    IframeContext,
    (iframe) => iframe.sendEvent
  );

  return {
    event,
    sendEvent,
  };
}
