import { URL_INITIAL_PARAMS } from "../utils/const/urlInitialParams";

interface InitialParams {
  token: string | null;
  liveKey: string;
  hasAwaitScreen: boolean;
  isGringa: boolean;
  isPolishop: boolean;
  isCountdown: boolean;
  name?: string;
  email?: string;
}
function useQuery() {
  return new URLSearchParams(window.location.search);
}

export function getQueryParam(key: string) {
  const q = new URLSearchParams(window.location.search);
  return q.get(key);
}

export function useInitialParams(): InitialParams {
  const pathSplited = window.location.pathname
    .split("/")
    .filter((item) => !!item);
  const query = useQuery();
  const _liveKey = pathSplited[1];

  return {
    name: query.get(URL_INITIAL_PARAMS.name) || undefined,
    email: query.get(URL_INITIAL_PARAMS.email) || undefined,
    token: query.get(URL_INITIAL_PARAMS.token),
    hasAwaitScreen: !!query.get(URL_INITIAL_PARAMS.hasAwaitScreen),
    isGringa: !!query.get(URL_INITIAL_PARAMS.isGringa),
    isCountdown: !!query.get(URL_INITIAL_PARAMS.isCountdown),
    isPolishop: !!query.get(URL_INITIAL_PARAMS.isPolishop),
    liveKey: _liveKey,
  };
}
