export function storage(key: string) {
  return {
    get: () => {
      try {
        return localStorage.getItem(key);
      } catch (error) {
        return null;
      }
    },
    setItem: (value: string) => {
      try {
        return localStorage.setItem(key, value);
      } catch (error) {
        return null;
      }
    },
  };
}
